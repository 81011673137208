import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import "react-responsive-modal/styles.css"
import Lightbox from "react-image-lightbox"
import { JSONLD, Product, Generic } from "react-structured-data"
import "react-image-lightbox/style.css"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Breadcrumb from "../components/layout/breadcrumb"
import ContactForm from "../components/bodycomponents/contact-form"
import { colors } from "../utils/colors"
import LocationIcon from "../images/icons/SSLLC_Icons_Location_Gray-1.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTag,
  faChevronLeft,
  faChevronRight,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons"

const ProductDetail = ({ data, location }) => {
  const tabs = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const [hoveredTab, setHoveredTab] = useState(null) // Track which tab is being hovered

  // Photo
  const [photoIndex, setPhotoIndex] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const itemsPerPage = 4

  const handleNext = () => {
    if (startIndex + itemsPerPage < fullImagesData.length) {
      setStartIndex(startIndex + 1)
    }
  }

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1)
    }
  }

  const productData = data.product

  useEffect(() => {
    if (productData.marketing) {
      setActiveTab(0);
    }
  }, [productData.marketing]);
  
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  // const [photoIndex, setPhotoIndex] = useState(0)
  const [paths, setPaths] = useState([])
  const [images, setImages] = useState(() => {
    const initialState = getImages()
    return initialState
  })
  const [primaryImage, setPrimaryImage] = useState(() => {
    const initialState = getPrimaryImage()
    return initialState
  })
  const [fullImagesData, setFullImagesData] = useState(() => {
    const initialState = getFullImagesData()
    return initialState
  })

  useEffect(() => {
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  }, [])

  useEffect(() => {
    let categories = productData.categories
    if (categories[categories.length - 1] === "/new-arrivals") categories.pop()

    let segments = categories[categories.length - 1].split("/")
    segments.shift()

    let paths = [
      { name: productData.category, path: `catalog/${segments[0]}` },
      { name: productData.subCategory, path: `catalog${categories[0]}` },
    ]
    if (categories.length === 2)
      paths.push({
        name: productData.subCategoryDetail,
        path: `catalog${categories[1]}`,
      })

    paths.push({ name: productData.name, path: location.pathname.slice(1) })

    setPaths(paths)
  }, [])

  function getPrimaryImage() {
    if (!productData.images || productData.images.length === 0) return null

    let primaryImageLocal = productData.images.filter(function (imageData) {
      return imageData.isPrimary === true
    })[0]
    return primaryImageLocal
  }

  function getFullImagesData() {
    if (!productData.images || productData.images.length === 0) return null

    var nonPrimaryImages = productData.images.filter(function (imageData) {
      return imageData.isPrimary === false
    })
    nonPrimaryImages.sort(function (a, b) {
      return a.sortOrder - b.sortOrder
    })
    return nonPrimaryImages
  }

  function getImages() {
    if (!productData.images || productData.images.length === 0) return null

    let primaryImageLocal = getPrimaryImage()
    let nonPrimaryImages = getFullImagesData()
    var imagesLocal = nonPrimaryImages.map((imageData) => imageData.fullImage)
    if (primaryImageLocal) imagesLocal.unshift(primaryImageLocal.fullImage)

    return imagesLocal
  }

  function getPrice() {
    const price = productData.askingPriceWebsite
    return price !== undefined ? price : null;
  }

  function formatPrice(price) {
    return price.toLocaleString("en-US")
  }

  const price = getPrice()

  // Form product details
  const productDetails = {
    code: productData.productCode,
  };

  return (
    <Layout>
      <SEO
        title={productData.name}
        description={productData.blurb}
        imageUrl={primaryImage ? primaryImage.fullImage : null}
        url={location.href}
        canonicalUrl={location.origin + location.pathname}
      />
      <JSONLD>
        <Product
          name={productData.name}
          description={productData.blurb}
          image={images}
          url={location.href}
          model={productData.model}
          sku={productData.productCode}
          mpn={productData.productCode}
          identifier={productData.serialNumber}
          releaseDate={productData.year}
          category={`${productData.category} > ${productData.subCategory} > ${productData.subCategoryDetail}`}
          itemCondition="https://schema.org/UsedCondition"
        >
          <Generic
            type="brand"
            jsonldtype="Brand"
            schema={{ name: productData.manufacturerName }}
          />
        </Product>
      </JSONLD>
      <Breadcrumb paths={paths} />
      <div className="container max-w-screen-xl py-8">
        <div className="py-6 flex flex-col md:flex-row justify-between space-x-0 md:space-x-6">
          <div className="w-full md:w-1/2 flex flex-col space-y-4 text-left">
            <div className="">
              <div className="pr-0 md:pr-3 pb-4">
                <a
                  title={`Image of ${productData.name}`}
                  onClick={() => {
                    setPhotoIndex(0)
                    setIsOpen(true)
                  }}
                >
                  <img
                    className="no-border max-h-[60vh]"
                    src={primaryImage ? primaryImage.fullImage : null}
                    alt={`Image of ${productData.name}`}
                  />
                </a>
              </div>

              <div className="relative mt-4">
                {/* Thumbnails Grid */}
                <div className="grid grid-cols-4 gap-4 px-8">
                  {fullImagesData &&
                    fullImagesData
                      .slice(startIndex, startIndex + itemsPerPage)
                      .map((imageData, i) => (
                        <a
                          key={imageData.fullImage}
                          className="swap-me"
                          onClick={() => {
                            setPhotoIndex(i + 1)
                            setIsOpen(true)
                          }}
                        >
                          <img
                            alt={`${productData.name} image ${i}`}
                            className="no-border"
                            src={imageData.gridImage}
                          />
                        </a>
                      ))}
                </div>

                {/* Navigation Buttons */}
                {fullImagesData && fullImagesData.length > itemsPerPage && (
                  <div className="flex justify-between items-center">
                    {/* Left Button */}
                    <button
                      className="absolute left-0 top-1/2 transform -translate-y-1/2 p-3 hover:text-red disabled:opacity-50"
                      onClick={handlePrev}
                      disabled={startIndex === 0}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                    </button>

                    {/* Right Button */}
                    <button
                      className="absolute right-0 top-1/2 transform -translate-y-1/2 p-3 hover:text-red disabled:opacity-50"
                      onClick={handleNext}
                      disabled={
                        startIndex + itemsPerPage >= fullImagesData.length
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} size="lg" />
                    </button>
                  </div>
                )}
              </div>
            </div>
            {isOpen && images && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % images.length)
                }
              />
            )}
          </div>
          <div className="w-full md:w-1/2 flex flex-col space-y-4 text-left">
            <p className="font-normal">
              <a href={`/manufacturer${productData.manufacturerSlug}/`}>
                {productData.manufacturerName}
              </a>
            </p>
            <h2 className="pb-8 text-balance">{productData.name}</h2>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <p className="w-full md:w-1/2 font-semibold text-dark-gray flex flex-row text-start items-start space-x-2">
                <img
                  className="inline-block mr-2 mt-1"
                  src={LocationIcon}
                  width={15}
                  height={15}
                  alt="A gray GPS pin"
                />
                {productData.websiteLocation}
              </p>
              <p className="w-full md:w-1/2 text-royal-blue font-semibold text-[1.3rem] flex flex-row text-start items-start space-x-2">
                {price !== null && (
                  <>
                    <FontAwesomeIcon
                      icon={faTag}
                      className="mr-2 mt-2 text-gray"
                    />
                    ${formatPrice(price)}
                  </>
                )}
              </p>
            </div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="w-full md:w-1/2 text-dark-gray flex flex-col text-start items-start justify-start">
                <p className="leading-6 mb-1.5">
                  <strong>Product Code:</strong> {productData.productCode}
                </p>
                <p className="leading-6 mb-1.5">
                  <strong>Year:</strong>{" "}
                  {productData.year ? productData.year : "N/A"}
                </p>
              </div>
              <div className="w-full md:w-1/2 text-dark-gray flex flex-col text-start items-start">
                <p className="leading-6 mb-1.5">
                  <strong>Serial:</strong>{" "}
                  {productData.serialNumber ? productData.serialNumber : "N/A"}
                </p>
                <p className="leading-6 mb-1.5">
                  <strong>Model:</strong> {productData.model}
                </p>
              </div>
            </div>
            <div className="py-4">
              <p>{productData.blurb}</p>
            </div>
            <div className="flex flex-col md:flex-row pt-2 space-y-4 md:space-y-0 md:space-x-6 text-center">
              <p>
                <a
                  href={"#quote-section"}
                  className="block w-full px-5 py-2 text-white hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal bg-red hover:bg-scarlet-red"
                >
                  Request Quote
                </a>
              </p>
              <p>
                <a
                  href="/sell-equipment"
                  target="_blank"
                  className="block w-full px-5 py-2 text-white hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal bg-royal-blue hover:bg-scarlet-red"
                >
                  Sell Similar
                </a>
              </p>
              <p>
                <a
                  href="/contact-us"
                  target="_blank"
                  className="block w-full px-5 py-2 text-charcoal hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal bg-light-gray hover:bg-medium-gray"
                >
                  Questions?
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="py-6">
          <div className="w-full bg-container-gray rounded-[15px]">
            {/* Tab Headers */}
            <div className="hidden md:flex w-full rounded-t-[15px]">
              {/* First Tab: Description */}
              <button
                className={`${
                  productData.description && productData.documents.length > 0
                    ? "w-1/3"
                    : "w-1/2"
                } flex items-center justify-center gap-2 px-6 py-5 font-semibold rounded-t-[15px] transition-colors duration-300 ${
                  activeTab === 0
                    ? "text-red border-b border-charcoal hover:border-red"
                    : "bg-red text-white hover:text-red border-b border-white hover:bg-light-gray"
                }`}
                onClick={() => setActiveTab(0)}
                onMouseEnter={() => setHoveredTab(0)} // Set hovered tab to 0
                onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
              >
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  size="xl"
                  style={{
                    color:
                      activeTab === 0 || hoveredTab === 0
                        ? "#c42724"
                        : "transparent",
                  }}
                />
                <h4 className="mb-0">Description</h4>
              </button>

              {/* Second Tab: Product Details */}
              {productData.description && (
                <button
                  className={`${
                    productData.documents.length > 0 ? "w-1/3" : "w-1/2"
                  } flex items-center justify-center gap-2 px-6 py-5 font-semibold rounded-t-[15px] transition-colors duration-300 ${
                    activeTab === 1
                      ? "text-red border-b border-charcoal hover:border-red"
                      : "bg-red text-white hover:text-red border-b border-white hover:bg-light-gray"
                  }`}
                  onClick={() => setActiveTab(1)}
                  onMouseEnter={() => setHoveredTab(1)} // Set hovered tab to 0
                  onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
                >
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    size="xl"
                    style={{
                      color:
                        activeTab === 1 || hoveredTab === 1
                          ? "#c42724"
                          : "transparent",
                    }}
                  />
                  <h4 className="mb-0">Product Details</h4>
                </button>
              )}
              {/* Third Tab: Documentation */}
              {productData.documents.length > 0 && (
                <button
                  className={`w-1/3 flex items-center justify-center gap-2 px-6 py-5 font-semibold rounded-t-[15px] transition-colors duration-300 ${
                    activeTab === 2
                      ? "text-red border-b border-charcoal hover:border-red"
                      : "bg-red text-white hover:text-red border-b border-white hover:bg-light-gray"
                  }`}
                  onClick={() => setActiveTab(2)}
                  onMouseEnter={() => setHoveredTab(2)} // Set hovered tab to 0
                  onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
                >
                  <FontAwesomeIcon
                    icon={faChevronCircleDown}
                    size="xl"
                    style={{
                      color:
                        activeTab === 2 || hoveredTab === 2
                          ? "#c42724"
                          : "transparent",
                    }}
                  />
                  <h4 className="mb-0">Documentation</h4>
                </button>
              )}
            </div>

            {/* Tab Content */}
            <div className="hidden md:block p-10 rounded-b-[15px] min-h-[50vh]">
              {activeTab === 0 && productData.marketing && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${productData.marketing}`,
                  }}
                  className="text-charcoal"
                />
              )}

              {activeTab === 1 && productData.description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${productData.description}`,
                  }}
                  className="text-charcoal"
                />
              )}
              {activeTab === 2 && productData.documents.length > 0 && (
                <div className="text-left items-start justify-start">
                  {productData.documents.map((doc) => (
                    <>
                      <a
                        key={doc.fileName}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={doc.url}
                      >
                        {doc.fileName}
                      </a>
                      <br />
                    </>
                  ))}
                </div>
              )}
            </div>

            {/* Accordion View for Small Screens */}
            <div className="block md:hidden">
              {/* First Tab: Description */}
              <div
                className={`rounded-t-[15px] flex flex-row items-center justify-center px-6 py-5 font-semibold transition-colors duration-300 ${
                  activeTab === 0
                    ? "text-red border-b border-charcoal hover:border-red"
                    : "bg-red text-white hover:text-red border-b border-red hover:bg-light-gray"
                }`}
                onClick={() => setActiveTab(0)}
                onMouseEnter={() => setHoveredTab(0)} // Set hovered tab to 0
                onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
              >
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  size="xl"
                  style={{
                    color:
                      activeTab === 0 || hoveredTab === 0
                        ? "#c42724"
                        : "transparent",
                  }}
                />
                <h4 className="mb-0">Description</h4>
              </div>
              {activeTab === 0 && productData.marketing && (
                <p
                  className="bg-light-gray p-4 rounded-b-[15px] text-charcoal"
                  dangerouslySetInnerHTML={{
                    __html: `${productData.marketing}`,
                  }}
                />
              )}

              {/* Second Tab: Product Details */}
              {productData.description && (
                <>
                  <div
                    className={` flex flex-row items-center justify-center px-6 py-5 font-semibold transition-colors duration-300 md:mt-2 ${
                      activeTab === 1
                        ? "text-red border-b border-charcoal hover:border-red"
                        : "bg-red text-white hover:text-red border-b border-red hover:bg-light-gray rounded-[15px]"
                    }`}
                    onClick={() => setActiveTab(1)}
                    onMouseEnter={() => setHoveredTab(1)} // Set hovered tab to 0
                    onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
                  >
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size="xl"
                      style={{
                        color:
                          activeTab === 1 || hoveredTab === 1
                            ? "#c42724"
                            : "transparent",
                      }}
                    />
                    <h4 className="mb-0">Product Details</h4>
                  </div>
                  {activeTab === 1 && (
                    <p
                      className="bg-light-gray p-4 rounded-b-[15px] text-charcoal"
                      dangerouslySetInnerHTML={{
                        __html: `${productData.description}`,
                      }}
                    />
                  )}
                </>
              )}
              {/* Third Tab: Documentation */}
              {productData.documents.length > 0 && (
                <>
                  <div
                    className={` flex flex-row items-center justify-center px-6 py-5 font-semibold transition-colors duration-300 md:mt-2 ${
                      activeTab === 2
                        ? "text-red border-b border-charcoal hover:border-red"
                        : "bg-red text-white hover:text-red border-b border-red hover:bg-light-gray rounded-[15px]"
                    }`}
                    onClick={() => setActiveTab(2)}
                    onMouseEnter={() => setHoveredTab(2)} // Set hovered tab to 0
                    onMouseLeave={() => setHoveredTab(null)} // Reset hovered tab
                  >
                    <FontAwesomeIcon
                      icon={faChevronCircleDown}
                      size="xl"
                      style={{
                        color:
                          activeTab === 2 || hoveredTab === 2
                            ? "#c42724"
                            : "transparent",
                      }}
                    />
                    <h4 className="mb-0">Product Details</h4>
                  </div>
                  {activeTab === 2 && productData.documents.length > 0 && (
                    <div className="text-left items-start justify-start">
                      {productData.documents.map((doc) => (
                        <>
                          <a
                            key={doc.fileName}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={doc.url}
                          >
                            {doc.fileName}
                          </a>
                          <br />
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="quote-section">
        <ContactForm
          title="Ready for a quote?"
          body={
            <p>Submit your request, and we’ll provide the details you need.</p>
          }
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.royalBlue}
          shadow="shadow-royalBlue-bottom"
          shadowClass="shadow-custom-in"
          formType="Product Inquiry"
          productDetails={productDetails}
        />
      </div>
    </Layout>
  )
}

export default ProductDetail

export const query = graphql`
  query ($slug: String!) {
    product(slug: { eq: $slug }) {
      name
      slug
      primaryPhoto
      model
      blurb
      description
      productCode
      askingPrice
      askingPriceWebsite
      serialNumber
      manufacturerName
      manufacturerSlug
      sfId
      websiteLocation
      images {
        fullImage
        gridImage
        isPrimary
        sortOrder
        thumbImage
      }
      category
      subCategory
      subCategoryDetail
      marketing
      soldStatus
      year
      capabilities
      video1
      video2
      documents {
        url
        fileName
      }
      categories
    }
  }
`
